import { CouponAPIResponse } from 'lib/coupons/interface';
import httpClient, { getResponseData } from 'lib/httpClient';
import { APISingleResponse } from 'lib/utils';

export const getUserReferralCodeUrl = (userId: string): string =>
  `/users/${userId}/coupon_referral`;

export const getUserReferralCode = async (userId: string) => {
  const { data } = await httpClient.get<GetUserReferralCodeResponse>(
    getUserReferralCodeUrl(userId),
  );

  return data.data;
};

export type GetUserReferralCodeResponse = APISingleResponse<CouponAPIResponse>;

export const putUpdateReferralCodeUrl = (
  couponCode: string,
): string => `/coupons/${couponCode}`;

export const putUpdateReferralCode = async ({
  oldReferralCode,
  newReferralCode,
}: PutUpdateReferralCodeInput) => {
  const {
    data,
  } = await httpClient.put<PutUpdateReferralCodeResponse>(
    putUpdateReferralCodeUrl(oldReferralCode),
    {
      coupon_code: newReferralCode,
    },
  );

  return data.data;
};

export interface PutUpdateReferralCodeInput {
  oldReferralCode: string;
  newReferralCode: string;
}
export type PutUpdateReferralCodeResponse = APISingleResponse<CouponAPIResponse>;

export const getUserRewardCoupons = async (userId) => {
  try {
    const response = await httpClient.get(
      `/coupons/${userId}/referral_reward`,
    );

    return getResponseData(response);
  } catch (err) {
    throw err;
  }
};

export const getUserReferrees = async (userId) => {
  try {
    const response = await httpClient.get(
      `/coupons/${userId}/referral_reward/users`,
    );

    return getResponseData(response);
  } catch (err) {
    throw err;
  }
};
