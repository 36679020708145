import { useAuth } from 'lib/auth';
import { useGet } from 'lib/swr-crud';
import {
  getUserReferralCode,
  getUserReferralCodeUrl,
  putUpdateReferralCode,
} from './api';
import { APIErrorResponse } from 'lib/utils';
import { AxiosError } from 'axios';

export const useUserReferralCode = () => {
  const { session, loading } = useAuth();

  const shouldFetch = !loading && session;

  const userId = session?.user?.userId;

  const { data, error, isLoading, mutate } = useGet<
    string,
    AxiosError<APIErrorResponse>
  >(
    shouldFetch ? getUserReferralCodeUrl(userId!) : null,
    async () => {
      const { coupon_code } = await getUserReferralCode(userId!);
      return coupon_code;
    },
  );

  const updateReferralCode = async ({
    oldReferralCode,
    newReferralCode,
  }) => {
    const {
      coupon_code: updatedReferralCode,
    } = await putUpdateReferralCode({
      oldReferralCode,
      newReferralCode,
    });

    return mutate(updatedReferralCode);
  };

  const referralCode = data || null;

  return {
    referralCode,
    updateReferralCode,
    error,
    isLoading,
    mutate,
  };
};
