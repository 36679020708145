import { AxiosError } from 'axios';
import { useAuth } from 'lib/auth';
import { useGet } from 'lib/swr-crud';
import {
  APIErrorResponse,
  APISortDirection,
  APIStringifiedBoolean,
} from 'lib/utils';
import { KeyedMutator } from 'swr/dist/types';
import {
  getUserLoyaltyPoints,
  GetUserLoyaltyPointsResponse,
  getUserLoyaltyPointsUrl,
} from './api';
import {
  LoyaltyPointAPISortBy,
  YbRepsAPIResponse,
  YbRepsListResponse,
} from './interface';

const useNearestExpiredLoyaltyPoint = (): Hook => {
  const { session, loading } = useAuth();

  const userId = session?.user?.userId;
  const shouldFetch = !loading && userId !== undefined;

  const param = {
    isUsable: APIStringifiedBoolean.TRUE,
    perPage: 1,
    sortField: LoyaltyPointAPISortBy.EXPIRED_AT,
    sortDir: APISortDirection.ASCENDING,
  };

  const { data, error, mutate, isValidating, isLoading } = useGet<
    GetUserLoyaltyPointsResponse,
    AxiosError<APIErrorResponse>
  >(
    shouldFetch ? getUserLoyaltyPointsUrl(userId!, param) : null,
    async () => await getUserLoyaltyPoints(userId!, param),
  );

  const oldestLoyaltyPoint = data?.data.yb_reps?.[0] || undefined;
  const totalLoyaltyPoints = data?.data.total || 0;
  const totalLoyaltyPointsInDollar = data?.data.total_in_dollar || 0;
  const redeemableLoyaltyPoints = data?.data.redeemable || 0;
  const redeemableLoyaltyPointsInDollar =
    data?.data.redeemable_in_dollar || 0;

  return {
    totalLoyaltyPoints,
    totalLoyaltyPointsInDollar,
    redeemableLoyaltyPoints,
    redeemableLoyaltyPointsInDollar,
    oldestLoyaltyPoint,
    error,
    mutate,
    isValidating,
    isLoading,
  };
};

interface Hook {
  totalLoyaltyPoints: number;
  totalLoyaltyPointsInDollar: number;
  redeemableLoyaltyPoints: number;
  redeemableLoyaltyPointsInDollar: number;
  oldestLoyaltyPoint?: YbRepsAPIResponse;
  error?: AxiosError<APIErrorResponse>;
  mutate: KeyedMutator<YbRepsListResponse>;
  isValidating: boolean;
  isLoading: boolean;
}

export { useNearestExpiredLoyaltyPoint };
