import { AxiosError } from 'axios';
import { useGet } from 'lib/swr-crud';
import { APIErrorResponse, APISortDirection } from 'lib/utils';
import { getOrdersByUserId, GetOrdersByUserIdResponse } from './api';
import { OrderAPISortBy, OrderAPIStatus } from './interface';

export const useUserOutstandingOrder = (
  userId: string | undefined,
) => {
  const shouldFetch = userId !== undefined;

  const { data, error, mutate, isValidating, isLoading } = useGet<
    GetOrdersByUserIdResponse,
    AxiosError<APIErrorResponse>
  >(shouldFetch ? `/users/${userId}/orders` : null, async () =>
    getOrdersByUserId(userId!, {
      perPage: '1',
      status: OrderAPIStatus.PAYMENT_RECEIVED,
      sortField: OrderAPISortBy.DELIVERY_DATETIME,
      sortDir: APISortDirection.ASCENDING,
    }),
  );

  const order = data?.data?.[0] || null;

  return {
    order,
    error,
    mutate,
    isValidating,
    isLoading,
  };
};
