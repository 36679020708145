export enum OrderAPIStatus {
  AWAITING_PAYMENT = 'awaiting-payment',
  PAYMENT_RECEIVED = 'payment-received',
  CANCELED = 'canceled',
  COMPLETED = 'completed',
  PAYMENT_PROCESSING = 'payment-processing',
  PAYMENT_FAILED = 'failed',
  CHARGE_REFUNDED = 'refunded',
}

export enum OrderAPISortBy {
  CREATED_AT = 'created_at',
  DELIVERY_DATETIME = 'delivery_datetime',
  STATUS = 'status',
  CUSTOMER_FIRST_NAME = 'customer_first_name',
  CUSTOMER_LAST_NAME = 'customer_last_name',
}

export enum OrderAPIDeliveryMethod {
  DOORSTEP_DELIVERY = 'internal',
  CLICK_AND_COLLECT = 'click-and-collect',
}
