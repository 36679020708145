import httpClient, {
  getErrorMessage,
  getResponseData,
} from 'lib/httpClient';
import {
  APIListResponse,
  APISingleResponse,
  APISortDirection,
  createQueryString,
} from 'lib/utils';
import {
  OrderAPIResponse,
  OrderAPISortBy,
  OrderAPIStatus,
} from './interface';

export const getUserOrders = async () => {
  const response = await httpClient.get('/orders/user');

  return getResponseData(response);
};

export const getOrderById = async (
  orderId: string,
): Promise<GetSingleOrdersResponse> => {
  const response = await httpClient.get<GetSingleOrdersResponse>(
    `/orders/${orderId}`,
  );

  return response.data;
};

export type GetSingleOrdersResponse = APISingleResponse<OrderAPIResponse>;

export const getOrderStatusList = async () => {
  try {
    const response = await httpClient.get('/order-statuses');

    return getResponseData(response);
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const getOrderInvoice = async (orderId) => {
  try {
    const response = await httpClient.post(
      `/orders/${orderId}/invoice`,
    );

    return response;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const getOrderInvoiceNow = async (orderId) => {
  try {
    const response = await httpClient.post(
      `/orders/${orderId}/invoice/now`,
      null,
      { responseType: 'blob' },
    );

    return response;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const putUpdateDeliveryDateTimeById = async (
  orderId,
  deliveryDateTime,
) => {
  try {
    const response = await httpClient.put(
      `/orders/${orderId}/delivery_slot`,
      { delivery_time: deliveryDateTime },
    );

    return getResponseData(response);
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const getOrdersByUserId = async (
  userId: string,
  param?: GetOrdersByUserIdOption,
): Promise<GetOrdersByUserIdResponse> => {
  const formattedQueryString = createQueryString({
    per_page: param?.perPage,
    status: param?.status,
    order_number: param?.orderNumber,
    sort_dir: param?.sortDir,
    sort_field: param?.sortField,
    delivery_start: param?.deliveryStart,
    delivery_end: param?.deliveryEnd,
  });

  const response = await httpClient.get<GetOrdersByUserIdResponse>(
    `/users/${userId}/orders?${formattedQueryString}`,
  );

  return response.data;
};

export interface GetOrdersByUserIdOption {
  perPage?: string;
  page?: string;
  status?: OrderAPIStatus;
  orderNumber?: string;
  sortDir?: APISortDirection;
  sortField?: OrderAPISortBy;
  deliveryStart?: string;
  deliveryEnd?: string;
}

export type GetOrdersByUserIdResponse = APIListResponse<OrderAPIResponse>;
